<template>
  <div>
    <div class="container">
      <div class="notice-box">
        <div class="notice-title">
          评审列表
        </div>

        <!-- 选择比赛状态tabs -->

        <div class="work-year">
          <div v-for="s in statuslist" :key="s" class="year-href" @click="selectStatus(s)">
            <span :class="[statusactive===s ? 'year-active' : '']">
              {{ s }}
            </span>
          </div>

          <div class="info-box">
            <div v-for="(info, idx) in judgeInfo" :key="idx">
              <div v-if="statusactive==='未完成'" class="download_container">
                <!-- 未完成评审列表布局 -->
                <div>
                  <el-row>
                    <el-col :span="15">
                      <div class="name">
                        {{ info.judgeName }}
                      </div>
                      <div class="register-time">
                        评审截止日期：{{ info.judgeEndTime }}
                      </div>
                    </el-col>
                    <el-col :span="9">
                      <div>
                        <el-button
                          class="btn-download btn-top"
                          @click="checkJudgeList(info.id)"
                        >进入评审
                        </el-button>
                        <!-- 预览 按钮 -->
                        <el-button
                          class="btn-preview btn-top"
                          @click="checkJudgeList(info.id)"
                        >
                          <svg-icon name="filejudge" color="#ffffff" width="18" />
                        </el-button>
                      </div>
                      <!-- <div>
                        <el-button
                          class="btn-download"
                          @click="checkJudgeList(info.id)"
                        >提交评审
                        </el-button>
                        <el-button
                          class="btn-preview"
                          @click="checkJudgeList(info.id)"
                        >
                          <svg-icon name="filefinish" color="#ffffff" width="18" />
                        </el-button>
                      </div> -->
                    </el-col>
                  </el-row>

                </div>
                <!-- 右上角图标：有间隙 -->
                <div class="mask" />
                <div class="mask_content">
                  进行中
                </div>
              </div>

              <div v-else class="download_container">
                <!-- 未完成评审列表布局 -->
                <div>
                  <el-row>
                    <el-col :span="15">
                      <div class="name">
                        {{ info.judgeName }}
                      </div>
                      <div class="register-time">
                        评审截止日期：{{ info.judgeEndTime }}
                      </div>
                      <!-- <div class="register-time">
                        评审提交日期：{{ info.judgeSubmitTime }}
                      </div> -->
                    </el-col>
                    <el-col :span="9">
                      <div>
                        <el-button
                          class="btn-download btn-judge-finish btn-top"
                          @click="checkJudgeList(info.id)"
                        >查看评审信息
                        </el-button>
                        <!-- 预览 按钮 -->
                        <el-button
                          class="btn-preview btn-top"
                          @click="checkJudgeList(info.id)"
                        >
                          <svg-icon name="filecheck" color="#ffffff" width="18" />
                        </el-button>
                      </div>
                    </el-col>
                  </el-row>

                </div>

                <!-- 右上角图标：有间隙 -->
                <div class="mask mask-color" />
                <div class="mask_content mask-color">
                  已结束
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import '@/icons/filejudge'
import '@/icons/filefinish'
import '@/icons/filecheck'
import { getOwnJudgeList } from '@/api/expert'

export default {
  data() {
    return {
      statuslist: ['未完成', '已完成'],
      statusactive: '未完成',

      userName: '',

      ownJudgeList: [{
        judgeName: '2021年北京市工业设计大赛——研究生组',
        judgeBeginTime: '2021-10-12',
        judgeEndTime: '2021-11-12',
        judgeSubmitTime: '2021-11-11',
        status: '颁奖结束'
      }]
    }
  },
  computed: {
    judgeInfo() {
      const filter = this.statusactive === '已完成' ? judge => judge.status !== '进行中' : judge => judge.status === '进行中'
      return this.ownJudgeList.filter(filter)
    }
  },
  mounted() {
    this.userName = this.globalGetUsername()
    console.log('userName', this.userName)
    this.getOwnJudgeList()
  },
  methods: {
    // 选择显示的作品评审状态
    selectStatus(status) {
      this.statusactive = status
    },
    // 进入评审列表界面
    checkJudgeList(id) {
      console.log('id')
      this.$router.push(`/teacher/judgedetaillist/${id}`)
    },
    getOwnJudgeList() {
      getOwnJudgeList().then(res => {
        this.ownJudgeList = res.data
      })
    }
  }
}
</script>

<style scoped>
@import '~@/styles/theme.css';

.border {
  border: 1px solid red;
  height: 30px;
}

.container {
  width: 100%;
  min-height: calc(100vh - 60px);
  background-color: var(--background-light-color);
  /* border: 1px solid red; */
  text-align: center;
}

.notice-box {
  display: inline-block;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  margin-top: 40px;
  height: 610px;
  width: 1050px;
  /* border: 1px solid red; */
}

.notice-title {
  text-align: center;
  margin-top: 10px;
  height: 80px;
  /* border: 1px solid red; */
  font-size: 20px;
  line-height: 80px;
}

.work-year {
  display: inline-block;
  width: 80%;
  margin: auto;
  text-align: left;
  /* width: 400px; */
}

.year-href {
  display: inline-block;
  width: 70px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-right: 15px;
  /* border: 1px solid red; */
  font-size: 16px;
  color: rgb(44, 43, 43);

}

.year-href:hover {
  cursor: pointer;
}

.year-active {
  display: inline-block;
  width: 100%;
  height: 100%;
  /* line-height: 30px; */
  color: var(--background-color);
  border-bottom: 2px solid var(--theme-color);
}

.info-box {
  width: 100%;
  height: 450px;
  overflow: auto;
}

/* 小框框 */
/* 下载报名表 容器 */
.download_container {
  width: 100%;
  height: 100px;
  display: inline-block;
  margin: auto;
  margin-top: 20px;
  border: 1px solid #595159;
  border-radius: 5px;
  padding-top: 15px;
  padding-bottom: 30px;
  /*  用于 标签 定位 */
  position: relative;
  overflow: hidden;
}

.name {
  margin-top: 13px;
  padding-left: 40px;
  font-size: 16px;
  font-weight: 800;
}

.judge-finish-name {
  margin-top: 10px;
}

.register-time {
  margin-top: 3px;
  padding-left: 40px;
  font-size: 10px;
}

/* 下载 图标 */
.svg-icon-upload {
  vertical-align: bottom;
}

/* 上传报名表 按钮 */
.btn-upload {
  display: inline-block;
  /* 定位相关 */
  vertical-align: bottom;
  height: 40px;
  border-radius: 8px 0 0 8px;
  margin-right: -10px;
  margin-left: 10px;
  border: none;
  /* 样式相关 */
  background: #6a5cb3;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  padding: 5px 20px;
}

/* 预览按钮 */
.btn-midline {
  display: inline-block;
  /* 设置div里面的文字无法被选中 */
  user-select: none;
  /* 定位相关 */
  vertical-align: bottom;
  height: 40px;
  border: none;
  border-radius: 0 8px 8px 0;
  /* 样式相关 */
  background: #5fdfc6;
  color: #fff;
  font-size: 14px;
  padding: 15px 15px;
}

/* 提示文字1 */
.tip_download1 {
  color: #7c7c7c;
  margin-top: 15px;
  cursor: pointer;
}

/* 提示文字2 */
.tip_download2 {
  color: #7c7c7c;
  font-size: 6px;
  margin-top: 5px;
}

/* 右上角有间隙 样式 */
.mask {
  position: absolute;
  top: 11px;
  right: -4px;
  width: 32px;
  height: 5px;
  transform: rotate(45deg);
  border-width: 0px 2px 2px 2px;
  border-style: solid;
  border-color: transparent transparent var(--theme-color) transparent;
}

.mask_content {
  position: absolute;
  top: 16px;
  right: -16px;
  width: 80px;
  height: 20px;
  transform: rotate(45deg);
  border-width: 0px 20px 20px 20px;
  border-style: solid;
  border-color: transparent transparent var(--theme-color) transparent;
  color: white;
  font-size: 13px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
}

.mask-color {
  border-color: transparent transparent rgb(167, 167, 167) transparent;
}

/* 上传报名表 容器 */
.upload_container {
  border: 1px solid #817481;
  border-radius: 5px;
  padding-top: 70px;
  padding-bottom: 30px;
  /*  用于 标签 定位 */
  position: relative;
  overflow: hidden;
}

/* 下载 图标 */
.svg-icon-download {
  transform: rotate(180deg);
  vertical-align: top;
}

/* 下载报名表 按钮 */
.btn-download {
  /* 定位相关 */
  vertical-align: top;
  height: 30px;
  width: 100px;
  border-radius: 8px 0 0 8px;
  margin-right: -10px;
  margin-left: 20px;
  border: none;
  /* 样式相关 */
  background: var(--background-color);
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  padding: 5px 20px;
}

.btn-bottom {
  margin-bottom: 10px;
}

.btn-judge-finish {
  width: 120px;
}

.btn-top {
  margin-top: 15px
}

/* 预览按钮 */
.btn-preview {
  /* 定位相关 */
  vertical-align: top;
  height: 30px;
  width: 35px;
  border: none;
  border-radius: 0 8px 8px 0;
  /* 样式相关 */
  background: var(--theme-color);
  color: #fff;
  font-size: 14px;
  padding: 5px 5px;
}

/* 提示文字1 */
.tip_upload1 {
  color: #7c7c7c;
  margin-top: 15px;
  cursor: pointer;
}

/* 提示文字2 */
.tip_upload2 {
  color: #7c7c7c;
  font-size: 6px;
  margin-top: 5px;
}

</style>
